body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, potluq, potluq>div {
  height: 100%
}

:root {
  --first-color: #7bb0d9;
  --second-color: #7679d1;
  --third-color: #76d1cc;
}

.primary-color-bg {
  background-color: #7bb0d9;
  background-color: var(--first-color);
}
.primary-color {
  color: #7bb0d9;
  color: var(--first-color)
}
.secondary-color-bg {
  background-color: #7679d1;
  background-color: var(--second-color);
}
.secondary-color {
  color: #7679d1;
  color: var(--second-color)
}
.tertiary-color-bg {
  background-color: #76d1cc;
  background-color: var(--third-color);
}
.tertiary-color {
  color: #76d1cc;
  color: var(--third-color)
}
.facebook-blue {
  color: #4267B2
}
.facebook-blue-bg {
  background-color: #4267B2;
}
.google-green {
  color: #0F9D58
}
.google-green-bg {
  background-color: #0F9D58;
}

img {
  width: 100%;
}

.Toastify__toast--default {
  background: #7679d1;
  background: var(--second-color);
  color: white;
  border-radius: 8px;
  text-align: center;
  height: 10px;
}
.Toastify__progress-bar.Toastify__progress-bar--default{
  color: #76d1cc;
  color: var(--third-color);
  background: #76d1cc;
  background: var(--third-color);
  background-color: #76d1cc;
  background-color: var(--third-color);
}

/* .toastStyle {
  color: #000;
  
  background: var(--second-color);
  background-color: var(--second-color);
  border: 1px solid #34A853 ;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1);
} */
.toastProgress {
  color: var(--tertiary-color);
  background: var(--tertiary-color);
  background-color: var(--tertiary-color);
}

.App {
  height: 100%;
  min-height: 100%;
  min-height: 100vh;
  width: auto;
  position: center;
}

.landing {
  position: absolute;
  left: 0;
  height: 90vh;
  width: 100vw;
  margin-bottom: -50px;
}
.landingOverlay {
  /* background-color: rgba(97, 140, 173, 0.473); */
  background-color: rgba(0, 0, 0, 0.445);
  position: absolute;
  /* top: 0; */
  left: 0;
  /* size: cover; */
  height: 90vh;
  width: 100vw;
  margin-bottom: -50px;
}
/* .logo {
  filter: drop-shadow(16px 16px 16px  rgba(0,0,0,0.9));
  color: var(--first-color);
} */

.land0 {
  background: url('https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/landing1.1.png') no-repeat;
  background-size: cover;
  background-position: center;
  /* filter: blur(2px);
  overflow: hidden; */
}
.land1 {
  background: url('https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/landing2.1.png') no-repeat;
  background-size: cover;
  background-position: center;
  /* filter: blur(2px);
  overflow: hidden; */
}
.land2 {
  background: url('https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/landing3.png') no-repeat;
  background-size: cover;
  background-position: center;
  /* filter: blur(2px);
  overflow: hidden; */
}
.land3 {
  background: url('https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/landing4.png') no-repeat;
  background-size: cover;
  background-position: center;
  /* filter: blur(2px);
  overflow: hidden; */
}
.landingBlur {
  /* width: 100%;
  height:100%; */
  size: cover;
  -webkit-filter: blur(5px);
          filter: blur(5px);
  overflow: hidden;
}

.landing-inner {
  padding-top: 80px;
}

.landingItemsZ {
  z-index: 2;
}

.dark-overlay {
  /* background-color: rgba(0, 0, 0, 0.7); */
  /* background-color:#7bb0d9D9; */
  size: cover;
  position: absolute;
  /* top: 0; */
  left: 0;
  width: 100%;
  /* height: 90%; */
  /* z-index:1; */
  /* filter: blur(5px); */

}
.btn:hover {
  cursor: pointer;
}
.nav-link {
  transition: all .2s ease-in-out; 
}
.nav-link:hover {
  transform: scale(1.1);
}
.navbar-brand {
  transition: all .2s ease-in-out; 
}
.navbar-brand:hover {
  transform: scale(1.1);
}
.notFoundContainer {
  position: absolute;
  left: 0;
  /* z-index: -1; */
  width: 100vw;
  height: 100vh;
  /* margin-bottom: -50px; */
  margin: 0;
  background: url('https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/empty+plate.jpg') no-repeat;
  background-size: cover;
  background-position: center;

}
.notFoundOverlay {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin:0;
}

.footerStyle {
  font-size: 1em;
  z-index: 2;
}

.card-form {
  opacity: 0.9;
}

.latest-profiles-img {
  width: 40px;
  height: 40px;
}

.form-control::-webkit-input-placeholder {
  color: #bbb !important;
}

.form-control::-ms-input-placeholder {
  color: #bbb !important;
}

.form-control::placeholder {
  color: #bbb !important;
}

.cursorPointer {
  cursor: pointer
}
.hoverRed {
  transition: color .7s ease;
}
.hoverRed:hover {
  transition: color .7s ease;
  color: red;
}
.hoverSpin {
  transition:  0.6s ease-out;
}
.hoverSpin:hover {
  transition:  0.6s ease-in;
  transform: rotateZ(90deg);
}
.recipeCardContainer {
  position: relative;
}
.removeButton {
  z-index: 2;
  position:absolute; 
  top:5px; 
  right:5px;
}
.removeTagButton {
  z-index: 2;
  position:absolute; 
  top:-15px; 
  right:-17px;
}
.tagText {
  color: black;
}
.removeCommentButton {
  z-index: 2;
  position:absolute; 
  top:0px; 
  right:-17px;
  opacity: .5;
  color: red;
  /* transition: color .7s ease; */
  transition:  0.6s ease-out;
}
.removeCommentButton:hover {
  /* transition: color .7s ease; */
  opacity: 1;
  color: black;
  transition:  0.6s ease-in;
  transform: rotateZ(90deg);
}
/* .chefContainer { */
/* width: 100%; */
/* margin: 1em auto; */
/* columns: 4; */
/* column-gap: 1em; */
/* } */

/* RESPONSIVE COLUMNS FOR chefcontainer*/
/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .chefContainer {
    -webkit-columns: 2;
            columns: 2;
    padding: 0;
  }
  .recipeImage2 {
    margin: 1em auto;
    -webkit-columns: 4;
            columns: 4;
    width: 100%;
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
            column-gap: 1em;
  }
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .chefContainer {
    -webkit-columns: 2;
            columns: 2;
  }
  .recipeImage2 {
    margin: 1em auto;
    -webkit-columns: 4;
            columns: 4;
    width: 50%;
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
            column-gap: 1em;
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .chefContainer {
    -webkit-columns: 3;
            columns: 3;
  }
  .recipeImage2 {
    margin: 1em auto;
    -webkit-columns: 4;
            columns: 4;
    width: 25%;
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
            column-gap: 1em;
  }
}
/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .chefContainer {
    -webkit-columns: 4;
            columns: 4;
  }
  .recipeImage2 {
    margin: 1em auto;
    -webkit-columns: 4;
            columns: 4;
    width: 25%;
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
            column-gap: 1em;
  }
}
/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .chefContainer {
    -webkit-columns: 6;
            columns: 6;
  }
  .recipeImage2 {
    margin: 1em auto;
    -webkit-columns: 4;
            columns: 4;
    width: 25%;
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
            column-gap: 1em;
  }
}

.chefContainer .chefCard {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  /* margin: 0 0 2em; */
  margin: 0em;
  padding: 0em;
  overflow: hidden;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
  position: relative;
}

.chefContainer .chefCard h3 {
  margin: 0;
  padding: 0 0;
  font-size: 1.2em;
}
.chefContainer .chefCard p {
  margin: 0;
  padding: 0 0;
  font-size: 1em;
}

.chefCard .follow {
  z-index: 2;
  position:absolute; 
  top:5px; 
  right:5px;
}

.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -20px; /*  gutter size offset */
  margin-top: 5px;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  /* padding-right: 10px; */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}

.recipeImage {
  width: 100%;
  margin: 1em auto;
  -webkit-columns: 4;
          columns: 4;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em;
}



.spinner {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.adcontainer {
  font-size: 10px;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: -1rem -1rem 0rem 0rem;
}

.scrolling-feedwrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 0rem -2rem 0rem -2rem;

  left: 0;
  /* z-index: -1; */
  /* width: 100vw; */
  width: auto;
  /* padding: 1rem 1rem 1rem 1rem; */
}

.suggestedTag {
  flex: 0 0 auto;
  background-color: #76d1cc;
  background-color: var(--third-color)
}

.firstTag {
  margin: 0rem 0rem 0rem 1rem;
  /* left: 2rem; */
}
